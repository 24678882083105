<template>
  <div class="bg-white p-4">
    <b-row no-gutters>
      <b-col cols="3">
        <rm-input
          outlined
          class="mr-2"
          preppend-icon-inner="search"
          placeholder="Search..."
        ></rm-input>
      </b-col>
      <b-col cols="1">
        <rm-base-button text="Search"></rm-base-button>
      </b-col>
      <b-col cols="6">
        <rm-base-button
          class="ml-2"
          text="Hide-Filter"
          bgColor="primary--light"
          textColor="primary"
          ><rm-base-icon slot="post-icon" class="ml-3" name="filter-icon"
        /></rm-base-button>
      </b-col>
      <b-col cols="2">
        <div class="d-flex w-100">
          <rm-base-button
            class="ml-auto"
            text="Export"
            bgColor="primary"
            textColor="white"
            ><rm-base-icon slot="post-icon" class="ml-2" name="export-icon"
          /></rm-base-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="2" class="bordered ml-3">
        <rm-drop-down outlined :options="filterOptions" />
      </b-col>
      <b-col cols="2" class="ml-2">
        <rm-drop-down outlined :options="filterOptions" />
      </b-col>
    </b-row>

    <div class="d-flex mb-5">
      <rm-base-button
        class="ml-3"
        text="Filter"
        bgColor="tertiary"
        textColor="white"
      ></rm-base-button>
      <rm-base-button
        class="ml-3"
        text="Clear Filter"
        bgColor="tertiary--light"
        textColor="tertiary"
      ></rm-base-button>
    </div>
    <b-table
      ref="clientsTable"
      thead-class="bg-gray-400 text-uppercase text-sm-2"
      tbody-class="text-sm"
      :selectable="true"
      hover
      :items="items"
      :fields="fields"
      @row-selected="onRowSelected"
    >
      <template v-slot:cell(check)="{ rowSelected, index }">
        <div class="d-flex justify-content-center align-items-center">
          <input
            type="checkbox"
            :checked="rowSelected"
            @click="handleRowSelect(rowSelected, index)"
          />
        </div>
      </template>
      <template #head(check)="">
        <input
          type="checkbox"
          :checked="selectAll && items.length == selected.length"
          @click="selectAll = !selectAll"
        />
      </template>

      <template #cell(status)="data">
        <div
          class="text-center px-2 py-1 rounded-lg"
          :class="
            data.item.status == 'pending'
              ? 'bg-warning--light text-warning'
              : 'text-success bg-success--light'
          "
        >
          <small>{{ data.item.status }}</small>
        </div>
      </template>

      <!-- Action column -->
      <template #cell(actions)="">
        <div class="d-flex">
          <rm-base-button
            text="View"
            class="font-weight-bold"
            bgColor="primary--light"
            textColor="primary"
          ></rm-base-button>
          <!-- <rm-base-button
            class="ml-2"
            text="Wave-Payment"
            bgColor="danger--light"
            textColor="danger"
          ></rm-base-button> -->
        </div>
        <!-- <div class="d-flex" v-else>
          <rm-base-button
            text="Reciept"
            bgColor="danger--light"
            textColor="danger"
          ></rm-base-button>
          <rm-base-button
            text="Later"
            class="ml-2"
            bgColor="tertiary--light"
            textColor="tertiary"
          ></rm-base-button>
        </div> -->
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectAll: false,
      filterOptions: ["Select Branch", "Select Commissions"],
      selected: [],
      // Note `isActive` is left out and will not appear in the rendered table
      fields: [
        {
          key: "check",
          // sortable: true,
          label: "",
        },
        {
          key: "clients",
          // sortable: true,
          label: "clients",
        },
        {
          key: "referrer",
          sortable: false,
          label: "Referrer",
        },
        {
          key: "sales",
          sortable: false,
          label: "Sales",
          class: "w-25",
        },
        {
          key: "date",
          sortable: false,
          label: "Date",
        },
        {
          key: "total_price",
          sortable: false,
          label: "Total Price",
        },
        {
          key: "amount_paid",
          sortable: false,
          label: "Amount Paid",
        },
        {
          key: "status",
          sortable: false,
          label: "Status",
        },
        {
          key: "actions",
          sortable: false,
          label: "",
        },
      ],
      items: [
        {
          // isActive: true,
          clients: "Nuel Ayo",
          referrer: "Adetunji Ola",
          sales:
            "Initial payment for 2 Starter plots at SATELITE TOWN (Outright payment plan)",
          date: "11/02/2021 - 11/03/21",
          total_price: "N4,000,000",
          amount_paid: "N4,000,000",
          status: "ongoing",
        },
        {
          // isActive: true,
          clients: "Nuel Ayo",
          referrer: "Adetunji Ola",
          sales:
            "Initial payment for 2 Starter plots at SATELITE TOWN (Outright payment plan)",
          date: "11/02/2021 - 11/03/21",
          total_price: "N4,000,000",
          amount_paid: "N4,000,000",
          status: "pending",
        },
      ],
    };
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.clientsTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.clientsTable.clearSelected();
    },
    selectRow(index) {
      this.$refs.clientsTable.selectRow(index);
    },
    unSelectRow(index) {
      this.$refs.clientsTable.unselectRow(index);
    },
    handleRowSelect(isRowSelected, index) {
      if (!isRowSelected) {
        this.selectRow(index);
      } else {
        this.unSelectRow(index);
      }
    },
  },
  watch: {
    selectAll(v) {
      if (!v) {
        this.clearSelected();
      } else {
        this.selectAllRows();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.bordered {
  border-left: 1px solid var(--primary);
}
</style>
